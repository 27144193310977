import React, { Component } from "react";
import {
  // HashRouter,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import "react-responsive-modal/styles.css";
// import TheLayout from "./containers/TheLayout";
import AboutUs from "./views/aboutUs";
import Contact from "./views/contact";
import Home from "./views/home";
import Originals from "./views/originals/originals";
import Project from "./views/project";
import "./custom-css/customStyle.css";
import "./custom-css/customCss.css";
import AminaJourney from "./views/originals/aminaJourney";
import Kore from "./views/originals/kore";
import Kweeto from "./views/originals/kweeto";
import Features from "./views/blogs/features";
import News from "./views/blogs/news";
import Training from "./views/careers/training";
import Internship from "./views/careers/internship";
import Jobs from "./views/careers/jobs";
import BlogInner from "./views/blogs/blogInner";
import ProjectsInner from "./views/projects/projectsInner";

function App() {
  // const loading = (
  //   <div className="pt-3 text-center">
  //     <div className="sk-spinner sk-spinner-pulse"></div>
  //   </div>
  // );

  return (
    <Router>
      {/* <React.Suspense fallback={loading}> */}
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/about">
          <AboutUs />
        </Route>
        <Route exact path="/originals">
          <Originals />
        </Route>
        <Route exact path="/blog/features">
          <Features />
        </Route>
        <Route exact path="/blog/news">
          <News />
        </Route>
        <Route exact path="/projects">
          <Project />
        </Route>
        <Route exact path="/projects-inner">
          <ProjectsInner />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/originals/amina">
          <AminaJourney />
        </Route>
        <Route exact path="/originals/kore">
          <Kore />
        </Route>
        <Route exact path="/originals/kweeto">
          <Kweeto />
        </Route>
        <Route exact path="/career/training">
          <Training />
        </Route>
        <Route exact path="/career/internship">
          <Internship />
        </Route>
        <Route exact path="/career/jobs">
          <Jobs />
        </Route>
        <Route exact path="/blog/post">
          <BlogInner />
        </Route>
        {/* <Route
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          /> */}
      </Switch>
      {/* </React.Suspense> */}
    </Router>
  );
}

export default App;
