import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../../containers/footer";
import TheHeader from "../../containers/TheHeader";

const News = () => {
  return (
    <Fragment>
      <TheHeader />

      <main className="js-animsition-overlay" data-animsition-overlay="true">
        {/* pos-rel start */}
        <section
          id="up"
          className="pos-rel section-bg-light-1"
          data-midnight="black"
        >
          {/* pos-rel start */}
          <div className="pos-rel flex-min-height-40vh">
            <div className="container padding-top-bottom-120 text-color-black after-preloader-anim">
              <h3 className="headline-xxxs hidden-box">
                <span className="anim-slide">News</span>
              </h3>
              <h2 className="subhead-xxl margin-top-20 anim-text-reveal tr-delay-03">
                90's mixtape single-origin coffee XOXO fashion axe butcher
                whatever, kinfolk cornhole retro cliche tofu humblebrag before
                they sold out. Af hammock listicle vape banjo echo park
                meditation organic cred subway tile.
              </h2>
            </div>
          </div>
          {/* pos-rel end */}
        </section>
        {/* pos-rel end */}
        {/* blog start */}
        <div
          id="down"
          className="pos-rel section-bg-light-2"
          data-midnight="black"
        >
          {/* pos-rel start */}
          <div className="pos-rel padding-top-30 padding-bottom-120">
            {/* flex-container start */}
            <div className="container flex-container flex-justify-center">
              {/* blog column start */}
              <div className="eight-columns column-100-100">
                {/* blog-entry start */}
                <article className="padding-top-90">
                  <div className="content-bg-light-2">
                    <a
                      href="post_dont-panic.html"
                      className="d-block hover-box js-pointer-large js-animsition-link"
                    >
                      <div className="hidden-box anim-overlay js-scrollanim">
                        <img
                          className="img-hover-scale"
                          src="assets/images/posts/photo-studio/photo-of-man-taking-photo-of-a-woman-model-3800832.jpg"
                          alt="Post"
                        />
                      </div>
                      <div className="margin-left-right-20 margin-top-20">
                        <h3 className="headline-xxxs hover-move-right text-color-black">
                          Second hand photography
                          <br />
                          studio equipment
                        </h3>
                        <br />
                        <p className="body-text-xs text-color-black margin-top-20 hover-move-right tr-delay-01">
                          Plaid retro migas venmo lo-fi ethical artisan
                          fingerstache seitan you probably haven't heard of them
                          fam keffiyeh. Scenester paleo knausgaard organic kogi
                          ramps asymmetrical schlitz...
                        </p>
                      </div>
                    </a>
                    <ul className="list list_row list_margin-30px padding-top-bottom-30 margin-left-right-20">
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs text-color-888888 text-hover-to-red js-pointer-small"
                        >
                          By: Jeffery Reder
                        </a>
                      </li>
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs text-color-888888 text-hover-to-red js-pointer-small"
                        >
                          In: Design
                        </a>
                      </li>
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs text-color-888888 text-hover-to-red js-pointer-small"
                        >
                          2020, April 12
                        </a>
                      </li>
                    </ul>
                  </div>
                </article>
                {/* blog-entry end */}
                {/* blog-entry start */}
                <article className="padding-top-90">
                  <div className="content-bg-light-2">
                    <a
                      href="post_dont-panic.html"
                      className="d-block hover-box js-pointer-large js-animsition-link"
                    >
                      <div className="hidden-box anim-overlay js-scrollanim">
                        <img
                          className="img-hover-scale"
                          src="assets/images/posts/home-studio/man-holding-microphone-singing-3388900.jpg"
                          alt="Post"
                        />
                      </div>
                      <div className="margin-left-right-20 margin-top-20">
                        <h3 className="headline-xxxs hover-move-right text-color-black">
                          Home recording studio
                          <br />
                          interior design
                        </h3>
                        <br />
                        <p className="body-text-xs text-color-black margin-top-20 hover-move-right tr-delay-01">
                          Plaid retro migas venmo lo-fi ethical artisan
                          fingerstache seitan you probably haven't heard of them
                          fam keffiyeh. Scenester paleo knausgaard organic kogi
                          ramps asymmetrical schlitz...
                        </p>
                      </div>
                    </a>
                    <ul className="list list_row list_margin-30px padding-top-bottom-30 margin-left-right-20">
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs text-color-888888 text-hover-to-red js-pointer-small"
                        >
                          By: Nick Hufnagel
                        </a>
                      </li>
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs text-color-888888 text-hover-to-red js-pointer-small"
                        >
                          In: Design
                        </a>
                      </li>
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs text-color-888888 text-hover-to-red js-pointer-small"
                        >
                          2020, April 7
                        </a>
                      </li>
                    </ul>
                  </div>
                </article>
                {/* blog-entry end */}

                {/* blog-entry end */}
              </div>
              {/* blog column end */}
            </div>
            {/* flex-container end */}
          </div>
          {/* pos-rel end */}
        </div>
        {/* blog end */}
      </main>

      <Footer />
    </Fragment>
  );
};

export default News;
