import React, { Fragment } from "react";
import Footer from "../containers/footer";
import TheHeader from "../containers/TheHeader";
// import ContactJumbotron from "./components/contact-jumbotron";

const AboutUs = () => {
  return (
    <Fragment>
      {/* <div
        style={{ height: "100vh", backgroundColor: "white" }}
        class="containers"
      >
        <div class="inner-container">
          <div class="shape"></div>
        </div>
      </div> */}
      {/* header start */}
      <TheHeader />
      {/* main start */}
      <main className="js-animsition-overlay" data-animsition-overlay="true">
        {/* page head start */}

        <section
          id="up"
          className="lines-section pos-rel anim-lines section-bg-light-1"
          data-midnight="black"
        >
          {/* lines-container start */}
          <div className="lines-container pos-rel no-lines flex-min-height-100vh">
            <div className="container padding-top-bottom-120 after-preloader-anim">
              <h3 className="headline-xxxs hidden-box text-color-black">
                <span className="anim-slide">About Us</span>
              </h3>
              <h2 className="subhead-xxl margin-top-20 anim-text-reveal tr-delay-03 text-color-black">
                Zero Gravity Studios is an award-winning, vibrant creative
                agency specializing in telling compelling stories through
                animation, design & film.
                {/* We are a young, vibrant digital creative agency specializing in
                telling compelling stories through design, multimedia & video. */}
              </h2>
              <br /> <br />
              <h3 className="headline-xxxs  text-color-black">
                {" "}
                Whether through branding, graphics, animation or film, we seek
                to empower our clients through the right mix of design &
                technology, giving them a powerful voice in the digital world.
                We seek to gain an understanding of your brand and vision and
                develop the right strategy and solutions that connect you with
                your customers and help you win. At our very core is the
                ideology that we must leave the world better than we found it.
                We do this by telling stories that touch people's lives
                positively. This also makes us passionate about storytelling for
                social impact and raising the next generation of animation &
                digital media talent across Africa.
                <br />
                <br />
                As well as winning the national leg of the Annecy 2019 Animation
                Du Monde contest in 2019, Zero Gravity Studios represented by
                co-founder Brian Wilson won the Digital Lab Africa 2021 Award in
                the Animation category.
                {/* We seek to empower our clients through design & technology,
                giving them a powerful voice in the digital world. Whether
                through branding, graphics, animation or video, we seek to gain
                an understanding of your brand and vision then develop solutions
                that connect you with your customers to achieve great results. */}
              </h3>
            </div>
          </div>
          {/* lines-container end */}
        </section>
        {/* </div>
        </div> */}
        {/* page head end */}

        {/* services start */}
        <section className="pos-rel section-bg-light-1" data-midnight="black">
          {/* pos-rel start */}
          <div className="pos-rel padding-top-bottom-120">
            {/* container start */}

            {/* container end */}
            {/* padding-top-bottom-90 start */}
            <div className="padding-top-bottom-90 text-color-black hidden-box pos-rel">
              {/* js-3-view-slider start */}
              <div className="js-3-view-slider margin-left-right-10">
                {/* swiper-wrapper start */}
                <div className="swiper-wrapper">
                  {/* swiper-slide start */}
                  <div className="swiper-slide">
                    <div className="margin-left-right-10">
                      <h3 className="headline-xs">Creators</h3>
                      <p className="body-text-m margin-top-20">
                        From initial concept to final delivery, we work with you
                        every step of the way, keeping great design and the “big
                        picture” at the core of everything we do.
                      </p>
                    </div>
                  </div>
                  {/* swiper-slide end */}
                  {/* swiper-slide start */}
                  <div className="swiper-slide">
                    <div className="margin-left-right-10">
                      <h3 className="headline-xs">Storytellers</h3>
                      <p className="body-text-m margin-top-20">
                        We are big on corporate storytelling and whether the
                        medium is a flyer or a Web commercial, creating a
                        narrative that makes your message memorable is always
                        our goal.
                      </p>
                    </div>
                  </div>
                  {/* swiper-slide end */}
                  {/* swiper-slide start */}
                  <div className="swiper-slide">
                    <div className="margin-left-right-10">
                      <h3 className="headline-xs">Strategists.</h3>
                      <p className="body-text-m margin-top-20">
                        We brainstorm and build think tanks between strategy and
                        creative teams to get form and functionality working
                        together seamlessly.
                      </p>
                    </div>
                  </div>
                  {/* swiper-slide end */}
                  {/* swiper-slide start */}
                  <div className="swiper-slide">
                    <div className="margin-left-right-10">
                      <h3 className="headline-xs">Thinkers.</h3>
                      <p className="body-text-m margin-top-20">
                        We study, test, tinker and play with technology and new
                        ideas. We start all projects with a digtal-first
                        approach and are big-picture thinkers. R&D is often a
                        vital part of our process.
                      </p>
                    </div>
                  </div>
                  {/* swiper-slide end */}
                </div>
                {/* swiper-wrapper end */}
                {/* swiper-button-prev start */}
                <div className="swiper-button-prev-box">
                  <div className="swiper-button-prev" />
                </div>
                {/* swiper-button-prev end */}
                {/* swiper-button-next start */}
                <div className="swiper-button-next-box">
                  <div className="swiper-button-next" />
                </div>
                {/* swiper-button-next end */}
                {/* swiper-pagination start */}
                <div className="pagination-box">
                  <div className="swiper-pagination counter-pagination black" />
                </div>
                {/* swiper-pagination end */}
              </div>
              {/* js-3-view-slider end */}
            </div>
            {/* padding-top-bottom-90 end */}
          </div>
          {/* pos-rel end */}
        </section>
        {/* services end */}

        {/* about end */}
        {/* partners start */}
        {/* <section className="lines-section pos-rel section-bg-dark-2">
        
          <div className="lines-container pos-rel no-lines flex-min-height-100vh">
        
            <div className="padding-top-bottom-120 container">
           
              <h2 className="headline-l text-center hidden-box js-scrollanim">
                <span className="anim-slide">Our Partners</span>
              </h2>
        
          
              <div className="padding-top-30">
                
                <div className="flex-container flex-justify-center">
                
                  <div className="three-columns padding-top-60">
                    <div className="text-center hover-box pos-rel">
                      <img
                        className="client-logo client-hover-out"
                        src="assets/images/clients/balancha.png"
                        alt="clients logo"
                      />
                      <img
                        className="client-logo client-hover-in pos-abs pos-center-center"
                        src="assets/images/clients/balancha-red.png"
                        alt="clients logo"
                      />
                    </div>
                  </div>
                  
                  <div className="three-columns padding-top-60">
                    <div className="text-center hover-box pos-rel">
                      <img
                        className="client-logo client-hover-out"
                        src="assets/images/clients/chayhana.png"
                        alt="clients logo"
                      />
                      <img
                        className="client-logo client-hover-in pos-abs pos-center-center"
                        src="assets/images/clients/chayhana-red.png"
                        alt="clients logo"
                      />
                    </div>
                  </div>
                
                  <div className="three-columns padding-top-60">
                    <div className="text-center hover-box pos-rel">
                      <img
                        className="client-logo client-hover-out"
                        src="assets/images/clients/dongolok.png"
                        alt="clients logo"
                      />
                      <img
                        className="client-logo client-hover-in pos-abs pos-center-center"
                        src="assets/images/clients/dongolok-red.png"
                        alt="clients logo"
                      />
                    </div>
                  </div>
         
                </div>
              
                <div className="flex-container flex-justify-center">
                
                  <div className="three-columns padding-top-60">
                    <div className="text-center hover-box pos-rel">
                      <img
                        className="client-logo client-hover-out"
                        src="assets/images/clients/eptep-septep.png"
                        alt="clients logo"
                      />
                      <img
                        className="client-logo client-hover-in pos-abs pos-center-center"
                        src="assets/images/clients/eptep-septep-red.png"
                        alt="clients logo"
                      />
                    </div>
                  </div>
               
                  <div className="three-columns padding-top-60">
                    <div className="text-center hover-box pos-rel">
                      <img
                        className="client-logo client-hover-out"
                        src="assets/images/clients/guldoyjan.png"
                        alt="clients logo"
                      />
                      <img
                        className="client-logo client-hover-in pos-abs pos-center-center"
                        src="assets/images/clients/guldoyjan-red.png"
                        alt="clients logo"
                      />
                    </div>
                  </div>
                  
                  <div className="three-columns padding-top-60">
                    <div className="text-center hover-box pos-rel">
                      <img
                        className="client-logo client-hover-out"
                        src="assets/images/clients/jorgo.png"
                        alt="clients logo"
                      />
                      <img
                        className="client-logo client-hover-in pos-abs pos-center-center"
                        src="assets/images/clients/jorgo-red.png"
                        alt="clients logo"
                      />
                    </div>
                  </div>
              
                  <div className="three-columns padding-top-60">
                    <div className="text-center hover-box pos-rel">
                      <img
                        className="client-logo client-hover-out"
                        src="assets/images/clients/judoboy.png"
                        alt="clients logo"
                      />
                      <img
                        className="client-logo client-hover-in pos-abs pos-center-center"
                        src="assets/images/clients/judoboy-red.png"
                        alt="clients logo"
                      />
                    </div>
                  </div>
        
                  <div className="three-columns padding-top-60">
                    <div className="text-center hover-box pos-rel">
                      <img
                        className="client-logo client-hover-out"
                        src="assets/images/clients/kizil-jindi.png"
                        alt="clients logo"
                      />
                      <img
                        className="client-logo client-hover-in pos-abs pos-center-center"
                        src="assets/images/clients/kizil-jindi-red.png"
                        alt="clients logo"
                      />
                    </div>
                  </div>
                
                  <div className="three-columns padding-top-60">
                    <div className="text-center hover-box pos-rel">
                      <img
                        className="client-logo client-hover-out"
                        src="assets/images/clients/meyman.png"
                        alt="clients logo"
                      />
                      <img
                        className="client-logo client-hover-in pos-abs pos-center-center"
                        src="assets/images/clients/meyman-red.png"
                        alt="clients logo"
                      />
                    </div>
                  </div>
               
                  <div className="three-columns padding-top-60">
                    <div className="text-center hover-box pos-rel">
                      <img
                        className="client-logo client-hover-out"
                        src="assets/images/clients/olchobay.png"
                        alt="clients logo"
                      />
                      <img
                        className="client-logo client-hover-in pos-abs pos-center-center"
                        src="assets/images/clients/olchobay-red.png"
                        alt="clients logo"
                      />
                    </div>
                  </div>
            
                </div>
             
              </div>
              
            </div>
           
          </div>
        
        </section> */}
        {/* partners end */}
      </main>
      {/* main end */}

      {/* <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul> */}
      {/* footer start */}
      <Footer />
    </Fragment>
  );
};

export default AboutUs;
