import React, { Fragment } from "react";
import ReactPlayer from "react-player";
import Footer from "../../containers/footer";

import TheHeader from "./../../containers/TheHeader";

const ProjectsInner = (props) => {
  // console.log(props.location);

  var index = localStorage.getItem("projectIndex");

  const videoData = [
    {
      name: "BlackBux",
      imageUrl: "avatar/projects/1.png",
      videoUrl: "https://vimeo.com/243203126",
      text: "",
      text2: "",
    },
    {
      name: "Get Home Safe",
      imageUrl: "avatar/projects/2.png",
      videoUrl: "https://vimeo.com/393055003",
      text: "",
      text2: "",
    },

    {
      name: "M36",
      imageUrl:
        "https://i.vimeocdn.com/video/1317565596-c0a8d802e36051ae43fcc9a740d139d9c1d51ca26f0969e15f6b8d610caaa1b7-d?mw=1200&mh=675&q=70",
      videoUrl: "https://vimeo.com/652952155",
      text: "The good folk at Union Bank reached out to us and tasked us with coming up with an Explainer Video to launch M36, a new independent, standalone investment platform designed to conveniently assist individuals grow, manage and preserve their wealth. The main target market for M36 is Affluent Individuals and the goal was to allow easy access by both customers and non-customers of Union Bank.",
      text2:
        "We were equipped with scripts and started out by working with the client to create an edit of their script that would work for animation/motion design after which we went straight into building a story around the script and then visual development. Working around their brand colours, we created a final piece that told their story and elevated the M36 brand.",
    },
    {
      name: "Pepsi Top 10 Countdown",
      imageUrl:
        "https://i.vimeocdn.com/video/1317566673-f9f07d8aac08a7c3655f44e8e3baf4051a0a22c90bfc355d9d1dbaa4f5492629-d_640x360",
      videoUrl: "https://vimeo.com/652952698",
      text: "We had a lot of fun creating this entire show package for Pepsi. We were given a lot of freedom to be creative and considering the audience for the youth focused show, we decided to go all out and make it fun with a hip hop vibe.",
      text2:
        "The entire package included The main show title, bumpers, Stings, Lower Thirds, Segment Breakers, Animated Countdown Numbers and other bits and bobs all created and animated within a tight deadline. Keep going to see some of the Behind-the-Scenes stuff!",
    },
    {
      name: "Padeya",
      imageUrl: "avatar/projects/3.png",
      videoUrl: "https://vimeo.com/288704563",
      text: "",
      text2: "",
    },
    {
      name: "Back2Life: Promo",
      imageUrl: "avatar/projects/4.png",
      videoUrl: "https://vimeo.com/257706788",
      text: "",
      text2: "",
    },
    {
      name: "Society for family health - Amina",
      imageUrl:
        "https://i.vimeocdn.com/video/998143277-bc1481173524e26f8b0701ac74a7cd8d82a79ac64615988b05a246a8c051747f-d_640x360",
      videoUrl: "https://vimeo.com/481224166",
      text: "During the lockdown, the Society for Family Health decided to launch a campaign to propagate the idea family planning for obvious reasons; people were all locked away in their houses and Nigeria's population is already at an all time high. We were tasked with telling stories using fun character animation in Yoruba, Hausa and Pidgin (Local Nigerian languages).",
      text2:
        "We were involved from the story development stage and provided character style and design, as well as the voices of the characters to produce a series of fun little animations delivered within the tight deadline.",
    },
    {
      name: "COVID CA",
      imageUrl:
        "https://i.vimeocdn.com/video/1317569021-1273a435e18a316bd7a09fd016ace7d6ad1da0e7102459ead334df6e9fa7f73e-d?mw=1200&mh=675&q=70",
      videoUrl: "https://vimeo.com/652953603",
      text: "On this project we were tasked to come up with a Public Service Announcement created primarily to pass across Covid regulations and safety tips to the hearing impaired. We see this as very thoughtful of the government agency and a great way to include members of our society who are often marginalised.",
      text2:
        "We worked closely with a sign-language expert and local language translators, giving us the sign language equivalent of the 3 language soundtracks that were provided by the client - Hausa, Igbo and Yoruba, the 3 major Nigerian languages. Using motion design and animation, we designed a graphic system that could tell the story visually even if it wasn't being heard and ended up with a PSA that was fun to watch and engaging, not just for the hearing impaired but for others who can hear as well.",
    },
    {
      name: "Water Wahala mini doc",
      imageUrl: "avatar/projects/5.png",
      videoUrl: "https://vimeo.com/252857028",
      text: "",
      text2: "",
    },
    {
      name: "Heirs Motor Insurance Ad",
      imageUrl:
        "https://i.vimeocdn.com/video/1268876189-7e18d606b4753918bb44cdec01306843c56dd0bd6864f0928_640x360",
      videoUrl: "https://vimeo.com/626529770",
      text: "Heirs Insurance, a company with UBA as their parent company recently launched some great and affordable insurance products targeted at young upwardly mobile people and anchored in the idea of providing value by doing things a little differently than usual. For their launch campaign, they wanted commercials that were engaging and different from the regular insurance commercial and that showed their difference.",
      text2:
        "We worked closely with the team and helped with developing the story. it was a very collaborative process where we shared ideas and we got to work with creative direction and visual development. Once the cast and  other details were approved, we got into production and produced a pair of commercials that were entertaining, informative and... different.",
    },
  ];

  return (
    <Fragment>
      <TheHeader />
      <main className="js-animsition-overlay" data-animsition-overlay="true">
        {/* <ul className="box-area">
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
        </ul> */}
        <div
          id="down"
          className="pos-rel section-bg-light-1"
          data-midnight="black"
        >
          {/* pos-rel start */}
          <div className="pos-rel padding-top-30 padding-bottom-120">
            {/* flex-container start */}
            <div className="container flex-container flex-justify-center">
              {/* post column start */}
              <div className="eight-columns column-100-100 padding-top-90">
                {/* content-bg-light-2 start */}
                <div className="content-bg-light padding-20">
                  {/* entry-content start */}
                  <article>
                    {/* <div className="padding-bottom-30">
                      <a
                        href="assets/images/posts/dont-panic/two-people-showing-fear-on-coronavirus-3952207.jpg"
                        className="anim-overlay js-scrollanim js-photo-popup js-pointer-zoom"
                      >
                        <img
                          src="assets/images/posts/dont-panic/two-people-showing-fear-on-coronavirus-3952207.jpg"
                          alt="Post"
                        />
                      </a>
                    </div> */}
                    <h2 className="headline-xs text-color-black text-center">
                      {videoData[index].name}
                      {/* Green juice roof */}
                    </h2>
                    <p className="body-text-xl text-color-black margin-top-20">
                      {videoData[index].text}
                      <br />
                      <ReactPlayer
                        url={videoData[index].videoUrl}
                        width="100%"
                        height="600px"
                        controls={true}
                      />
                      <br />
                      {videoData[index].text2}
                    </p>
                  </article>
                  {/* entry-content end */}

                  {/* similar posts end */}
                  {/* comments start */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </Fragment>
  );
};

export default ProjectsInner;
