import React, { Fragment } from "react";
import Footer from "../../containers/footer";
import TheHeader from "../../containers/TheHeader";

const BlogInner = () => {
  return (
    <Fragment>
      <TheHeader />
      <main className="js-animsition-overlay" data-animsition-overlay="true">
        {/* pos-rel start */}
        <section
          id="up"
          className="pos-rel bg-img-cover"
          style={{
            backgroundImage:
              "url(assets/images/posts/dont-panic/adult-beautiful-blur-casual-374703.jpg)",
          }}
        >
          {/* bg-overlay */}
          <div className="bg-overlay-black" />
          {/* pos-rel start */}
          <div className="pos-rel flex-min-height-100vh">
            <div className="padding-top-bottom-120 container small after-preloader-anim">
              {/* title start */}
              <div className="text-center">
                <h2 className="headline-xl anim-fade">
                  Don't panic. Don't panic. Don't panic.
                </h2>
              </div>
            </div>
          </div>
          {/* pos-rel end */}
        </section>
        {/* pos-rel end */}
        {/* post start */}
        <div
          id="down"
          className="pos-rel section-bg-light-1"
          data-midnight="black"
        >
          {/* pos-rel start */}
          <div className="pos-rel padding-top-30 padding-bottom-120">
            {/* flex-container start */}
            <div className="container flex-container flex-justify-center">
              {/* post column start */}
              <div className="eight-columns column-100-100 padding-top-90">
                {/* content-bg-light-2 start */}
                <div className="content-bg-light-2 padding-20">
                  {/* entry-content start */}
                  <article>
                    {/* <div className="padding-bottom-30">
                      <a
                        href="assets/images/posts/dont-panic/two-people-showing-fear-on-coronavirus-3952207.jpg"
                        className="anim-overlay js-scrollanim js-photo-popup js-pointer-zoom"
                      >
                        <img
                          src="assets/images/posts/dont-panic/two-people-showing-fear-on-coronavirus-3952207.jpg"
                          alt="Post"
                        />
                      </a>
                    </div> */}
                    <h2 className="headline-xs text-color-black text-center">
                      Green juice roof
                    </h2>
                    <p className="body-text-xl text-color-black margin-top-20">
                      Green juice roof party kombucha mixtape chartreuse. Tofu
                      retro cold-pressed letterpress coloring book gastropub yr
                      asymmetrical cred bicycle rights snackwave XOXO raw denim
                      keytar. Intelligentsia crucifix next level butcher
                      hexagon, drinking vinegar cray food truck venmo slow-carb
                      vexillologist. Intelligentsia wolf gastropub, twee lomo
                      small batch slow-carb.
                      <br />
                      <br />
                      Viral pug actually shoreditch four dollar toast crucifix
                      hammock authentic vice narwhal gluten-free cray everyday
                      carry marfa. Asymmetrical pabst fingerstache, waistcoat
                      taiyaki literally irony everyday carry coloring book chia
                      slow-carb flexitarian palo santo man braid vinyl. 90's pug
                      vape lomo affogato four dollar toast plaid.
                    </p>

                    <div className="padding-top-30">
                      <a
                        href="assets/images/posts/dont-panic/white-paper-with-i-love-you-print-3991793.jpg"
                        className="anim-overlay js-scrollanim js-photo-popup js-pointer-zoom"
                      >
                        <img
                          src="assets/images/posts/dont-panic/white-paper-with-i-love-you-print-3991793.jpg"
                          alt="Post"
                        />
                      </a>
                    </div>
                  </article>
                  {/* entry-content end */}

                  {/* content-bg-light-2 end */}
                  {/* similar posts start */}
                  <div className="padding-top-90">
                    <h2 className="headline-xxxs text-color-black">
                      You might also like
                    </h2>
                    {/* flex-container start */}
                    <div className="flex-container">
                      {/* column start */}
                      <div className="four-columns padding-top-30">
                        <a
                          href="post_red-cars.html"
                          className="column-r-margin-20 d-block hover-box js-pointer-large js-animsition-link"
                        >
                          <div className="hidden-box">
                            <img
                              className="img-hover-scale"
                              src="assets/images/posts/red-cars/close-up-photo-of-car-rims-2811496.jpg"
                              alt="Post"
                            />
                          </div>
                          <h3 className="headline-xxxxs text-color-black margin-top-10 hover-move-right">
                            Do red cars cost
                            <br />
                            more to insure
                          </h3>
                        </a>
                      </div>
                      {/* column end */}
                      {/* column start */}
                      <div className="four-columns padding-top-30">
                        <a
                          href="post_dont-panic.html"
                          className="column-l-r-margin-10 d-block hover-box js-pointer-large js-animsition-link"
                        >
                          <div className="hidden-box">
                            <img
                              className="img-hover-scale"
                              src="assets/images/posts/dont-panic/adult-beautiful-blur-casual-374703.jpg"
                              alt="Post"
                            />
                          </div>
                          <h3 className="headline-xxxxs text-color-black margin-top-10 hover-move-right">
                            Don't panic. Don't panic.
                            <br />
                            Don't panic.
                          </h3>
                        </a>
                      </div>
                      {/* column end */}
                      {/* column start */}
                      <div className="four-columns padding-top-30">
                        <a
                          href="post_good-morning.html"
                          className="column-l-margin-20 d-block hover-box js-pointer-large js-animsition-link"
                        >
                          <div className="hidden-box">
                            <img
                              className="img-hover-scale"
                              src="assets/images/posts/good-morning/beans-beverage-black-coffee-breakfast-373888.jpg"
                              alt="Post"
                            />
                          </div>
                          <h3 className="headline-xxxxs text-color-black margin-top-10 hover-move-right">
                            Good morning!
                            <br />
                            Have a nice day
                          </h3>
                        </a>
                      </div>
                      {/* column end */}
                    </div>
                    {/* flex-container end */}
                  </div>
                  {/* similar posts end */}
                  {/* comments start */}
                </div>
              </div>
              {/* post column end */}
              <br />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </Fragment>
  );
};

export default BlogInner;
