import React, { Fragment, useState } from "react";
import TheHeader from "../containers/TheHeader";
import { Modal } from "react-responsive-modal";
import ReactPlayer from "react-player";
import Footer from "../containers/footer";
import { Link } from "react-router-dom";

const Project = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [urlLink, setUrlLink] = useState(null);

  const videoData = [
    {
      name: "BlackBux",
      imageUrl: "avatar/projects/1.png",
      videoUrl: "https://vimeo.com/243203126",
      text: "",
      text2: "",
    },
    {
      name: "Get Home Safe",
      imageUrl: "avatar/projects/2.png",
      videoUrl: "https://vimeo.com/393055003",
      text: "",
      text2: "",
    },

    {
      name: "M36",
      imageUrl:
        "https://i.vimeocdn.com/video/1317565596-c0a8d802e36051ae43fcc9a740d139d9c1d51ca26f0969e15f6b8d610caaa1b7-d?mw=1200&mh=675&q=70",
      videoUrl: "https://vimeo.com/652952155",
      text: "The good folk at Union Bank reached out to us and tasked us with coming up with an Explainer Video to launch M36, a new independent, standalone investment platform designed to conveniently assist individuals grow, manage and preserve their wealth. The main target market for M36 is Affluent Individuals and the goal was to allow easy access by both customers and non-customers of Union Bank.",
      text2:
        "We were equipped with scripts and started out by working with the client to create an edit of their script that would work for animation/motion design after which we went straight into building a story around the script and then visual development. Working around their brand colours, we created a final piece that told their story and elevated the M36 brand.",
    },
    {
      name: "Pepsi Top 10 Countdown",
      imageUrl:
        "https://i.vimeocdn.com/video/1317566673-f9f07d8aac08a7c3655f44e8e3baf4051a0a22c90bfc355d9d1dbaa4f5492629-d_640x360",
      videoUrl: "https://vimeo.com/652952698",
      text: "We had a lot of fun creating this entire show package for Pepsi. We were given a lot of freedom to be creative and considering the audience for the youth focused show, we decided to go all out and make it fun with a hip hop vibe.",
      text2:
        "The entire package included The main show title, bumpers, Stings, Lower Thirds, Segment Breakers, Animated Countdown Numbers and other bits and bobs all created and animated within a tight deadline. Keep going to see some of the Behind-the-Scenes stuff!",
    },
    {
      name: "Padeya",
      imageUrl: "avatar/projects/3.png",
      videoUrl: "https://vimeo.com/288704563",
      text: "",
      text2: "",
    },
    {
      name: "Back2Life: Promo",
      imageUrl: "avatar/projects/4.png",
      videoUrl: "https://vimeo.com/257706788",
      text: "",
      text2: "",
    },
    {
      name: "Society for family health - Amina",
      imageUrl:
        "https://i.vimeocdn.com/video/998143277-bc1481173524e26f8b0701ac74a7cd8d82a79ac64615988b05a246a8c051747f-d_640x360",
      videoUrl: "https://vimeo.com/481224166",
      text: "During the lockdown, the Society for Family Health decided to launch a campaign to propagate the idea family planning for obvious reasons; people were all locked away in their houses and Nigeria's population is already at an all time high. We were tasked with telling stories using fun character animation in Yoruba, Hausa and Pidgin (Local Nigerian languages).",
      text2:
        "We were involved from the story development stage and provided character style and design, as well as the voices of the characters to produce a series of fun little animations delivered within the tight deadline.",
    },
    {
      name: "COVID CA",
      imageUrl:
        "https://i.vimeocdn.com/video/1317569021-1273a435e18a316bd7a09fd016ace7d6ad1da0e7102459ead334df6e9fa7f73e-d?mw=1200&mh=675&q=70",
      videoUrl: "https://vimeo.com/652953603",
      text: "On this project we were tasked to come up with a Public Service Announcement created primarily to pass across Covid regulations and safety tips to the hearing impaired. We see this as very thoughtful of the government agency and a great way to include members of our society who are often marginalised.",
      text2:
        "We worked closely with a sign-language expert and local language translators, giving us the sign language equivalent of the 3 language soundtracks that were provided by the client - Hausa, Igbo and Yoruba, the 3 major Nigerian languages. Using motion design and animation, we designed a graphic system that could tell the story visually even if it wasn't being heard and ended up with a PSA that was fun to watch and engaging, not just for the hearing impaired but for others who can hear as well.",
    },
    {
      name: "Water Wahala mini doc",
      imageUrl: "avatar/projects/5.png",
      videoUrl: "https://vimeo.com/252857028",
      text: "",
      text2: "",
    },
    {
      name: "Heirs Motor Insurance Ad",
      imageUrl:
        "https://i.vimeocdn.com/video/1268876189-7e18d606b4753918bb44cdec01306843c56dd0bd6864f0928_640x360",
      videoUrl: "https://vimeo.com/626529770",
      text: "Heirs Insurance, a company with UBA as their parent company recently launched some great and affordable insurance products targeted at young upwardly mobile people and anchored in the idea of providing value by doing things a little differently than usual. For their launch campaign, they wanted commercials that were engaging and different from the regular insurance commercial and that showed their difference.",
      text2:
        "We worked closely with the team and helped with developing the story. it was a very collaborative process where we shared ideas and we got to work with creative direction and visual development. Once the cast and  other details were approved, we got into production and produced a pair of commercials that were entertaining, informative and... different.",
    },
  ];

  return (
    <Fragment>
      {/* header start */}

      <Modal open={open} onClose={onCloseModal} center>
        <h2>Simple centered modal</h2>
      </Modal>

      <TheHeader />
      {/* main start */}
      <main className="js-animsition-overlay" data-animsition-overlay="true">
        {/* featured articles start */}
        <Modal open={open} onClose={onCloseModal} center>
          <ReactPlayer url={urlLink} />
        </Modal>

        <section
          className="pos-rel originals section-bg-light-2"
          data-midnight="black"
        >
          {/* pos-rel start */}
          <div className="pos-rel shape  padding-top-bottom-120">
            {/* title start */}
            <h2 className="headline-l text-center  ">
              <span className=" text-color-black" data-text="Projects">
                Projects
              </span>
            </h2>
            <div className="row d-flex flex-justify-center">
              <div className="col-lg-8 col-md-8">
                <p className="text-center headline-xxs text-dark mt-4 p-3">
                  Welcome to Zero Gravity. Float around and take a look at some
                  of our visual projects executed by our talented team and
                  featuring Financial Services, Beauty, Health, Insurance, Tech
                  and other sectors. From various short-form animation and film
                  projects covering advertising, trailers, online and original
                  content. We excel at bringing ideas and brands to life and
                  taking projects from concept to delivery no matter the brief.
                </p>
              </div>
            </div>
            {/* title end */}
            {/* recent posts end */}
            <div className="container-fluid padding-top-bottom-90 hidden-box pos-rel ">
              <div className="row g-2">
                {videoData.map((item, id) => (
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <Link
                      style={{ color: "white" }}
                      to="/projects-inner"
                      onClick={() => {
                        localStorage.setItem("projectIndex", id);
                      }}
                      // state={{
                      //   videoUrl: item.videoUrl,
                      //   text1: item.text,
                      //   text2: item.text2,
                      // }}
                    >
                      <div
                        className="hover-box hidden-box my-box pos-rel"
                        // onClick={() => {
                        //   setUrlLink(item.videoUrl);
                        //   onOpenModal();
                        //   console.log("print ptint");
                        // }}
                      >
                        <div className="text-bg-container ">
                          <div className="project-name-st d-flex align-items-center justify-content-center">
                            {/* <h3 className="headline-xxxs">{item.name}</h3> */}
                            <h3 className="headline-xxxs">{item.name} </h3>
                          </div>
                        </div>

                        <img
                          className="img-hover-scale"
                          src={item.imageUrl}
                          alt="Post"
                          height="300px"
                          width="100%"
                          style={{ objectFit: "cover" }}
                        />
                      </div>{" "}
                    </Link>
                  </div>
                ))}
              </div>
            </div>{" "}
          </div>
        </section>

        {/* featured articles end */}
        {/* <div className="row">
          <div className="col-md-4">
            <div id="curve" className="card">
              <img
                src="avatar/projects/5.png"
                alt=""
                style={{ objectFit: "cover" }}
              />
              <div className="footer">
                <div className="info">
                  <div className="name">Filan Fisteku</div>
                  <div className="job">Architect Manager</div>
                </div>
              </div>
              <div className="card-blur" />
            </div>
          </div>
        </div> */}
      </main>

      <Footer />
    </Fragment>
  );
};

export default Project;

{
  /* js-2-view-slider start */
}
<div className="js-2-view-slider padding-top-bottom-90 hidden-box pos-rel">
  {/* swiper-wrapper start */}
  <div className="swiper-wrapper js-slider-scroll-anim">
    {/* swiper-slide start */}
    <article className="swiper-slide">
      <div className="anim-img-scale">
        <a
          href="post_dont-panic.html"
          className="d-block hover-box js-animsition-link js-pointer-large"
        >
          <img
            className="anim-img-scale__inner"
            // src="assets/images/posts/tattoo-artist/woman-in-pink-long-sleeve-shirt-wearing-eyeglasses-4125604-2.jpg"
            src="avatar/projects/1.png"
            alt="Post"
          />
          <h3 className="headline-xxxs pos-abs pos-left-bottom hover-move-right">
            <span className="hidden-box d-block">
              <span className="anim-slide tr-delay-04">Tattoo artist</span>
            </span>
            <span className="hidden-box d-block">
              <span className="anim-slide tr-delay-05">
                apprenticeship portfolio
              </span>
            </span>
          </h3>
        </a>
        {/* <ul className="pos-abs pos-left-top list list_row list_margin-30px">
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs anim-text-fill tr-delay-04 js-pointer-small"
                          data-text="By: Nick Hufnagel"
                        >
                          By: Nick Hufnagel
                        </a>
                      </li>
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs anim-text-fill tr-delay-05 js-pointer-small"
                          data-text="In: Art"
                        >
                          In: Art
                        </a>
                      </li>
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs anim-text-fill tr-delay-06 js-pointer-small"
                          data-text="2020, April 28"
                        >
                          2020, April 28
                        </a>
                      </li>
                    </ul> */}
      </div>
    </article>
    {/* swiper-slide end */}
    {/* swiper-slide start */}
    <article className="swiper-slide">
      <div className="anim-img-scale">
        <a
          href="post_dont-panic.html"
          className="d-block hover-box js-animsition-link js-pointer-large"
        >
          <img
            className="anim-img-scale__inner"
            // src="assets/images/posts/colorful-eyeshadows/woman-wearing-black-leather-top-2712948-2.jpg"
            src="avatar/projects/2.png"
            alt="Post"
          />
          <h3 className="headline-xxxs pos-abs pos-left-bottom hover-move-right">
            <span className="hidden-box d-block">
              <span className="anim-slide tr-delay-04">Colorful eyeshadow</span>
            </span>
            <span className="hidden-box d-block">
              <span className="anim-slide tr-delay-05">makeup tutorial</span>
            </span>
          </h3>
        </a>
        {/* <ul className="pos-abs pos-left-top list list_row list_margin-30px">
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs anim-text-fill tr-delay-04 js-pointer-small"
                          data-text="By: Jeffery Reder"
                        >
                          By: Jeffery Reder
                        </a>
                      </li>
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs anim-text-fill tr-delay-05 js-pointer-small"
                          data-text="In: Art"
                        >
                          In: Art
                        </a>
                      </li>
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs anim-text-fill tr-delay-06 js-pointer-small"
                          data-text="2020, April 29"
                        >
                          2020, April 29
                        </a>
                      </li>
                    </ul> */}
      </div>
    </article>
    {/* swiper-slide end */}
    {/* swiper-slide start */}
    <article className="swiper-slide">
      <div className="anim-img-scale">
        <a
          href="post_dont-panic.html"
          className="d-block hover-box js-animsition-link js-pointer-large"
        >
          <img
            className="anim-img-scale__inner"
            // src="assets/images/posts/insomnia/time-lapse-photography-of-person-on-tunnel-3162980.jpg"
            src="avatar/projects/3.png"
            alt="Post"
          />
          <h3 className="headline-xxxs pos-abs pos-left-bottom hover-move-right">
            <span className="hidden-box d-block">
              <span className="anim-slide tr-delay-04">Insomnia symptoms</span>
            </span>
            <span className="hidden-box d-block">
              <span className="anim-slide tr-delay-05">
                causes and treatment
              </span>
            </span>
          </h3>
        </a>
        {/* <ul className="pos-abs pos-left-top list list_row list_margin-30px">
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs anim-text-fill tr-delay-04 js-pointer-small"
                          data-text="By: Nick Hufnagel"
                        >
                          By: Nick Hufnagel
                        </a>
                      </li>
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs anim-text-fill tr-delay-05 js-pointer-small"
                          data-text="In: Culture"
                        >
                          In: Culture
                        </a>
                      </li>
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs anim-text-fill tr-delay-06 js-pointer-small"
                          data-text="2020, April 30"
                        >
                          2020, April 30
                        </a>
                      </li>
                    </ul> */}
      </div>
    </article>
    {/* swiper-slide end */}
    {/* swiper-slide start */}
    <article className="swiper-slide">
      <div className="anim-img-scale">
        <a
          href="post_dont-panic.html"
          className="d-block hover-box js-animsition-link js-pointer-large"
        >
          <img
            className="anim-img-scale__inner"
            // src="assets/images/posts/home-studio/man-using-light-control-panel-3355358.jpg"
            src="avatar/projects/5.png"
            alt="Post"
          />
          <h3 className="headline-xxxs pos-abs pos-left-bottom hover-move-right">
            <span className="hidden-box d-block">
              <span className="anim-slide tr-delay-04">
                Home recording studio
              </span>
            </span>
            <span className="hidden-box d-block">
              <span className="anim-slide tr-delay-05">interior design</span>
            </span>
          </h3>
        </a>
        {/* <ul className="pos-abs pos-left-top list list_row list_margin-30px">
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs anim-text-fill tr-delay-04 js-pointer-small"
                          data-text="By: Nick Hufnagel"
                        >
                          By: Nick Hufnagel
                        </a>
                      </li>
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs anim-text-fill tr-delay-05 js-pointer-small"
                          data-text="In: Design"
                        >
                          In: Design
                        </a>
                      </li>
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs anim-text-fill tr-delay-06 js-pointer-small"
                          data-text="2020, April 7"
                        >
                          2020, April 7
                        </a>
                      </li>
                    </ul> */}
      </div>
    </article>
    {/* swiper-slide end */}
    {/* swiper-slide start */}
    <article className="swiper-slide">
      <div className="anim-img-scale">
        <a
          href="post_dont-panic.html"
          className="d-block hover-box js-animsition-link js-pointer-large"
        >
          <img
            className="anim-img-scale__inner"
            // src="assets/images/posts/decor-and-furniture/man-seating-on-couch-1726074.jpg"
            src="avatar/projects/6.png"
            alt="Post"
          />
          <h3 className="headline-xxxs pos-abs pos-left-bottom hover-move-right">
            <span className="hidden-box d-block">
              <span className="anim-slide tr-delay-04">
                Home decor and furniture
              </span>
            </span>
            <span className="hidden-box d-block">
              <span className="anim-slide tr-delay-05">stores near me</span>
            </span>
          </h3>
        </a>
        {/* <ul className="pos-abs pos-left-top list list_row list_margin-30px">
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs anim-text-fill tr-delay-04 js-pointer-small"
                          data-text="By: Nancy Newton"
                        >
                          By: Nancy Newton
                        </a>
                      </li>
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs anim-text-fill tr-delay-05 js-pointer-small"
                          data-text="In: Art"
                        >
                          In: Art
                        </a>
                      </li>
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs anim-text-fill tr-delay-06 js-pointer-small"
                          data-text="2020, March 2"
                        >
                          2020, March 2
                        </a>
                      </li>
                    </ul> */}
      </div>
    </article>
    {/* swiper-slide end */}
  </div>
  {/* swiper-wrapper end */}
  {/* swiper-button-prev start */}
  <div className="swiper-button-prev-box">
    <div className="swiper-button-prev" />
  </div>
  {/* swiper-button-prev end */}
  {/* swiper-button-next start */}
  <div className="swiper-button-next-box">
    <div className="swiper-button-next" />
  </div>
  {/* swiper-button-next end */}
  {/* swiper-pagination start */}
  <div className="pagination-box">
    <div className="swiper-pagination counter-pagination" />
  </div>
  {/* swiper-pagination end */}
</div>;
{
  /* js-2-view-slider end */
}
// </div>
