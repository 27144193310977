import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
// import Background from '/assets/new/bg12.png'

const Footer = () => {
  return (
    <Fragment>
      <footer
        style={{ backgroundImage: `url(/assets/new/bg12.png)` }}
        className="fixed-footer pos-rel bg-white js-fixed-footer"
        // style={{
        //   backgroundImage: "url(avatar/kore1.png)",
        // }}
      >
        {/* <div className="animation-area"> */}
        {/* <ul className="box-area">
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
        </ul> */}
        {/* </div> */}

        {/* bg-overlay */}
        <div className="bg-overlay-black" />
        {/* pos-rel start */}
        <div className="pos-rel flex-min-height-100vh">
          {/* container start */}
          <div className="container padding-top-bottom-120">
            {/* footer-logo start */}
            <div className="footer-logo footer-logo-desktop-hidden padding-bottom-90">
              <img
                className="footer-logo__img"
                src="avatar/zg-logo.svg"
                alt="logo"
              />
            </div>
            {/* footer-logo end */}
            {/* Let's bring your story to life */}
            {/* flex-container start */}
            <div className="flex-container flex-align-center">
              {/* column start */}
              <div className="four-columns">
                <h4 className="column-l-r-margin-10 headline-l footer-title">
                  Let's bring <br />
                  <span className="custom-yellow">
                    Your Story
                    <br />
                  </span>{' '}
                  to life
                </h4>
              </div>
              <div className="four-columns">
                <img
                  className="mt-5 white"
                  src="avatar/zg-logo.svg"
                  height="150px"
                  width="auto"
                  alt="logo"
                />
              </div>
              {/* column end */}
              {/* column start */}
              <div className="four-columns footer-nav-mobile-padding">
                <ul className="column-l-r-margin-10 footer-nav-list js-footer-hover-box">
                  <li>
                    <Link
                      href="/"
                      className="footer-nav__btn js-pointer-small js-footer-hover-link"
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      className="footer-nav__btn js-pointer-small js-footer-hover-link"
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/originals"
                      className="footer-nav__btn js-pointer-small js-footer-hover-link"
                    >
                      Originals
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/projects"
                      className="footer-nav__btn js-pointer-small js-footer-hover-link"
                    >
                      Project
                    </Link>
                  </li>
                  {/* <li>
                    <a
                      href="#"
                      className="footer-nav__btn js-pointer-small js-footer-hover-link"
                    >
                      Blog
                    </a>
                  </li> */}
                  <li>
                    <Link
                      to="/contact"
                      className="footer-nav__btn js-pointer-small js-footer-hover-link"
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
              {/* column end */}
            </div>
            {/* flex-container end */}
            {/* flex-container start */}
            <div className="flex-container flex-justify-center padding-top-30">
              {/* column start */}
              <div className="four-columns column-50-100 padding-top-60 footer-email">
                <div className="column-l-r-margin-10">
                  <div className="headline-xxxs">Email</div>
                  <div className="margin-top-10">
                    <a
                      href="#"
                      className="subhead-s custom-yellow js-pointer-small"
                    >
                      hello@zerogravitystudios.co
                    </a>
                    {/* <br />
                    <a
                      href="#"
                      className="subhead-xxs text-color-b0b0b0 text-hover-to-white js-pointer-small"
                    >
                      email@umaya_job.com
                    </a> */}
                  </div>
                </div>
              </div>
              {/* column end */}
              {/* column start */}
              <div className="four-columns column-50-100 padding-top-60 footer-address">
                <div className="column-l-r-margin-10">
                  <div className="headline-xxxs">Address</div>
                  <div className="margin-top-10">
                    <a
                      href="#"
                      className="subhead-s custom-yellow js-pointer-small"
                    >
                      Coming Soon
                    </a>
                  </div>
                </div>
              </div>
              {/* column end */}
              {/* column start */}
              <div className="four-columns column-50-100 padding-top-60 footer-phone">
                <div className="column-l-r-margin-10">
                  <div className="headline-xxxs">Phone</div>
                  <div className="margin-top-10">
                    <a
                      href="#"
                      className="subhead-s custom-yellow js-pointer-small"
                    >
                      07025431603
                    </a>
                    {/* <br />
                    <a
                      href="#"
                      className="subhead-xxs text-color-b0b0b0 text-hover-to-white js-pointer-small"
                    >
                      +23 8 9635 7335
                    </a> */}
                  </div>
                </div>
              </div>
              {/* column end */}
            </div>
            {/* flex-container end */}
            {/* footer-social start */}
            <ul className="footer-social-desktop-hidden list list_center padding-top-90">
              <li className="list__item">
                <a
                  href="#"
                  className="flip-btn js-pointer-small"
                  data-text="Facebook"
                >
                  Facebook
                </a>
              </li>
              <li className="list__item">
                <a
                  href="#"
                  className="flip-btn js-pointer-small"
                  data-text="Dribbble"
                >
                  Dribbble
                </a>
              </li>
              <li className="list__item">
                <a
                  href="#"
                  className="flip-btn js-pointer-small"
                  data-text="Instagram"
                >
                  Instagram
                </a>
              </li>
              <li className="list__item">
                <a
                  href="#"
                  className="flip-btn js-pointer-small"
                  data-text="Youtube"
                >
                  Youtube
                </a>
              </li>
              <li className="list__item">
                <a
                  href="#"
                  className="flip-btn js-pointer-small"
                  data-text="Behance"
                >
                  Behance
                </a>
              </li>
              <li className="list__item">
                <a
                  href="#"
                  className="flip-btn js-pointer-small"
                  data-text="Twitter"
                >
                  Twitter
                </a>
              </li>
            </ul>
            {/* footer-social end */}
            {/* footer-copyright start */}
            <div className="footer-copyright text-center pos-abs pos-bottom-center">
              <p className="copyright-style">
                © 2021{' '}
                <span className="text-weight-700 js-pointer-small">
                  Zero gravity studios.
                </span>{' '}
                All Rights Reserved.
                {/* <a href="#" className="text-weight-700 js-pointer-small">
                  Jinna Gik
                </a> */}
              </p>
            </div>
            {/* footer-copyright end */}
          </div>
          {/* container end */}
        </div>
        {/* pos-rel end */}
      </footer>
    </Fragment>
  )
}

export default Footer
