import React, { Fragment } from "react";
import Footer from "../../containers/footer";
import TheHeader from "../../containers/TheHeader";

const AminaJourney = () => {
  return (
    <Fragment>
      <div>
        {/* scroll down btn end */}

        <TheHeader />

        {/* main start */}
        <main className="js-animsition-overlay" data-animsition-overlay="true">
          {/* lines-section start */}
          <section
            id="up"
            className="lines-section pos-rel anim-lines js-parallax-bg"
            style={{
              backgroundImage: "url(avatar/amina/1.png)",
            }}
          >
            {/* bg-overlay */}
            <div className="bg-overlay-black" />
            {/* lines-container start */}
            <div className="lines-container pos-rel anim-lines flex-min-height-100vh">
              <div className="padding-top-bottom-120 container small after-preloader-anim">
                {/* title start */}
                <div className="text-center">
                  <h2 className="headline-xl anim-fade">Aminah's Journey</h2>
                </div>
                {/* title end */}
                {/* description start */}
                <div className="flex-container description">
                  <div className="four-columns column-50-100 padding-top-1 text-center">
                    <span className="hidden-box d-inline-block">
                      <span className="subhead-xxs anim-reveal">
                        By: B.O Wilson
                      </span>
                    </span>
                  </div>
                  <div className="four-columns column-50-100 padding-top-1 text-center">
                    <span className="hidden-box d-inline-block">
                      <span className="subhead-xxs anim-reveal tr-delay-02">
                        Technique: 2D Animation
                      </span>
                    </span>
                  </div>
                  <div className="four-columns column-50-100 padding-top-1 text-center">
                    <span className="hidden-box d-inline-block">
                      <span className="subhead-xxs anim-reveal tr-delay-04">
                        Format: 90 Mins
                      </span>
                    </span>
                  </div>
                </div>
                {/* description end */}
              </div>
            </div>
            {/* lines-container end */}
          </section>
          {/* lines-section end */}
          {/* post start */}
          <div
            id="down"
            className="lines-section pos-rel black-lines section-bg-light-1"
            data-midnight="black"
          >
            {/* lines-container start */}
            <div className="lines-container pos-rel no-lines padding-top-bottom-120">
              {/* entry-content start */}
              <article>
                <div className="container small text-center">
                  <h2 className="headline-s text-color-black text-center">
                    Aminah's Journey
                  </h2>
                  <p className="body-text-s text-color-black margin-top-20">
                    A young girl from northern Nigeria escapes a Boko Haram
                    attack on her village only to become a refugee in her own
                    country. She survives the journey to safety by using her
                    imagination and teamwork.
                    <br />
                    <br />
                  </p>
                  <h5 class="headline-xxxs text-color-black">
                    {" "}
                    Format: 90 Mins{" "}
                  </h5>
                  <h5 class="headline-xxxs text-color-black">
                    {" "}
                    Technique: 2D Animation
                  </h5>{" "}
                  <h5 class="headline-xxxs text-color-black">
                    {" "}
                    Genre: Adventure, Fantasy
                  </h5>{" "}
                  <h5 class="headline-xxxs text-color-black">
                    {" "}
                    Language: English, Hausa
                  </h5>{" "}
                  <h5 class="headline-xxxs text-color-black"> Target: 11+</h5>
                </div>
              </article>
              {/* entry-content end */}
              {/* post start */}
              <div
                id="down"
                className="lines-section pos-rel black-lines section-bg-light-1"
                data-midnight="black"
              >
                {/* lines-container start */}
                <div className="lines-container pos-rel no-lines padding-top-bottom-120">
                  {/* entry-content start */}
                  <article>
                    <div className="container small">
                      <div className="padding-top-10">
                        <div className="padding-top-20">
                          <div className="anim-overlay js-scrollanim">
                            <a
                              href="avatar/amina/1.png"
                              className="d-block js-photo-popup js-pointer-zoom"
                            >
                              <img src="avatar/amina/1.png" alt="Post" />
                            </a>
                          </div>
                        </div>
                        <div className="padding-top-20">
                          <div className="anim-overlay js-scrollanim">
                            <a
                              href="avatar/amina/2.png"
                              className="d-block js-photo-popup js-pointer-zoom"
                            >
                              <img src="avatar/amina/2.png" alt="Post" />
                            </a>
                          </div>
                        </div>
                        <div className="padding-top-20">
                          <div className="anim-overlay js-scrollanim">
                            <a
                              href="avatar/amina/3.png"
                              className="d-block js-photo-popup js-pointer-zoom"
                            >
                              <img src="avatar/amina/3.png" alt="Post" />
                            </a>
                          </div>
                        </div>
                        <div className="padding-top-20">
                          <div className="anim-overlay js-scrollanim">
                            <a
                              href="avatar/amina/4.png"
                              className="d-block js-photo-popup js-pointer-zoom"
                            >
                              <img src="avatar/amina/4.png" alt="Post" />
                            </a>
                          </div>
                        </div>
                        <div className="padding-top-20">
                          <div className="anim-overlay js-scrollanim">
                            <a
                              href="avatar/amina/5.png"
                              className="d-block js-photo-popup js-pointer-zoom"
                            >
                              <img src="avatar/amina/5.png" alt="Post" />
                            </a>
                          </div>
                        </div>
                        {/* <div className="padding-top-20">
                          <div className="anim-overlay js-scrollanim">
                            <a
                              href="avatar/amina/6.png"
                              className="d-block js-photo-popup js-pointer-zoom"
                            >
                              <img src="avatar/amina/6.png" alt="Post" />
                            </a>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </article>
                  {/* entry-content end */}
                </div>
              </div>
            </div>
          </div>

          <Footer />
          {/* next post end */}
        </main>
        {/* main end */}
      </div>
    </Fragment>
  );
};

export default AminaJourney;
