import React, { Fragment } from "react";
import Footer from "../../containers/footer";
import TheHeader from "../../containers/TheHeader";

const Kore = () => {
  return (
    <Fragment>
      <div>
        {/* scroll down btn end */}

        <TheHeader />

        {/* main start */}
        <main className="js-animsition-overlay" data-animsition-overlay="true">
          {/* lines-section start */}
          <section
            id="up"
            className="lines-section pos-rel anim-lines js-parallax-bg"
            style={{
              backgroundImage: "url(avatar/Kore/1.png)",
            }}
          >
            {/* bg-overlay */}
            <div className="bg-overlay-black" />
            {/* lines-container start */}
            <div className="lines-container pos-rel anim-lines flex-min-height-100vh">
              <div className="padding-top-bottom-120 container small after-preloader-anim">
                {/* title start */}
                <div className="text-center">
                  <h2 className="headline-xl anim-fade">Kóré</h2>
                </div>
                {/* title end */}
                {/* description start */}
                <div className="flex-container description">
                  <div className="four-columns column-50-100 padding-top-1 text-center">
                    <span className="hidden-box d-inline-block">
                      <span className="subhead-xxs anim-reveal">
                        By: B.O Wilson
                      </span>
                    </span>
                  </div>
                  <div className="four-columns column-50-100 padding-top-1 text-center">
                    <span className="hidden-box d-inline-block">
                      <span className="subhead-xxs anim-reveal tr-delay-02">
                        Technique: 2D Animation
                      </span>
                    </span>
                  </div>
                  <div className="four-columns column-50-100 padding-top-1 text-center">
                    <span className="hidden-box d-inline-block">
                      <span className="subhead-xxs anim-reveal tr-delay-04">
                        Format: 90 Mins
                      </span>
                    </span>
                  </div>
                </div>
                {/* description end */}
              </div>
            </div>
            {/* lines-container end */}
          </section>
          {/* lines-section end */}
          {/* post start */}
          <div
            id="down"
            className="lines-section pos-rel black-lines section-bg-light-1"
            data-midnight="black"
          >
            {/* lines-container start */}
            <div className="lines-container pos-rel no-lines padding-top-bottom-120">
              {/* entry-content start */}
              <article>
                <div className="container small text-center">
                  <h2 className="headline-s text-color-black text-center">
                    Kore: The Power of Afrobeat
                  </h2>
                  <p className="body-text-s text-color-black margin-top-20">
                    Kóré, a gifted boy with dreams of becoming an afrobeat
                    musician, receives a magical Kakaki (An African Horn
                    instrument) which grows more powerful as it is played. He
                    uses the power of music and friendship with his close allies
                    Gan Gan and Amele to defeat bad guys and go on new
                    adventures across Africa that teach them the history of
                    Africas music.
                    <br />
                    <br />
                  </p>
                  <br />
                  <br />
                  <h5 class="headline-xxxs text-color-black">
                    {" "}
                    Format: 52 x 5'
                  </h5>
                  <h5 class="headline-xxxs text-color-black">
                    {" "}
                    Technique: 2D Animation
                  </h5>{" "}
                  <h5 class="headline-xxxs text-color-black">
                    {" "}
                    Genre: Fantasy, Adventure
                  </h5>{" "}
                  <h5 class="headline-xxxs text-color-black">
                    {" "}
                    Language: English, Hausa
                  </h5>{" "}
                  <h5 class="headline-xxxs text-color-black">
                    {" "}
                    Target: Kids + Family
                  </h5>
                </div>
              </article>
              {/* entry-content end */}
              {/* post start */}
              <div
                id="down"
                className="lines-section pos-rel black-lines section-bg-light-1"
                data-midnight="black"
              >
                {/* lines-container start */}
                <div className="lines-container pos-rel no-lines padding-top-bottom-120">
                  {/* entry-content start */}
                  <article>
                    <div className="container small">
                      <div className="padding-top-10">
                        <div className="padding-top-20">
                          <div className="anim-overlay js-scrollanim">
                            <a
                              href="avatar/Kore/1.png"
                              className="d-block js-photo-popup js-pointer-zoom"
                            >
                              <img src="avatar/Kore/1.png" alt="Post" />
                            </a>
                          </div>
                        </div>
                        <div className="padding-top-20">
                          <div className="anim-overlay js-scrollanim">
                            <a
                              href="avatar/Kore/2.png"
                              className="d-block js-photo-popup js-pointer-zoom"
                            >
                              <img src="avatar/Kore/2.png" alt="Post" />
                            </a>
                          </div>
                        </div>
                        {/*     <div className="padding-top-20">
                          <div className="anim-overlay js-scrollanim">
                            <a
                              href="avatar/amina/3.png"
                              className="d-block js-photo-popup js-pointer-zoom"
                            >
                              <img src="avatar/amina/3.png" alt="Post" />
                            </a>
                          </div>
                        </div>
                        <div className="padding-top-20">
                          <div className="anim-overlay js-scrollanim">
                            <a
                              href="avatar/amina/4.png"
                              className="d-block js-photo-popup js-pointer-zoom"
                            >
                              <img src="avatar/amina/4.png" alt="Post" />
                            </a>
                          </div>
                        </div>
                        <div className="padding-top-20">
                          <div className="anim-overlay js-scrollanim">
                            <a
                              href="avatar/amina/5.png"
                              className="d-block js-photo-popup js-pointer-zoom"
                            >
                              <img src="avatar/amina/5.png" alt="Post" />
                            </a>
                          </div>
                        </div> */}
                        {/* <div className="padding-top-20">
                          <div className="anim-overlay js-scrollanim">
                            <a
                              href="avatar/amina/6.png"
                              className="d-block js-photo-popup js-pointer-zoom"
                            >
                              <img src="avatar/amina/6.png" alt="Post" />
                            </a>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </article>
                  {/* entry-content end */}
                </div>
              </div>
            </div>
          </div>

          <Footer />
          {/* next post end */}
        </main>
        {/* main end */}
      </div>
    </Fragment>
  );
};

export default Kore;
