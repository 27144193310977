import React, { Fragment } from "react";
import Footer from "../../containers/footer";
import TheHeader from "../../containers/TheHeader";

const Jobs = () => {
  return (
    <Fragment>
      <TheHeader />
      <main
        className="js-animsition-overlay"
        data-animsition-overlay="true"
        data-target="dark"
      >
        {/* page head start */}
        <section
          id="up"
          className="pos-rel section-bg-light-1"
          data-midnight="black"
        >
          {/* pos-rel start */}
          <div className="pos-rel flex-min-height-40vh">
            <div className="container padding-top-bottom-120 text-color-black after-preloader-anim">
              <h3 className="headline-xxxs hidden-box">
                <span className="anim-slide">Jobs</span>
              </h3>
              <h2 className="subhead-xxl margin-top-20 anim-text-reveal tr-delay-03">
                Zero Gravity Studios is an award-winning, vibrant creative
                agency specializing in telling compelling stories through
                animation, design & film. Whether through branding, graphics,
                animation or film, we seek to empower our clients through the
                right mix of design & technology, giving them a powerful voice
                in the digital world. At our very core is the ideology that we
                must leave the world better than we found it. We do this by
                telling stories that touch people's lives positively. This also
                makes us passionate about storytelling for social impact and
                raising the next generation of animation & digital media talent
                across Africa.
              </h2>
            </div>
          </div>
          {/* pos-rel end */}
        </section>
        {/* page head end */}
        {/* services start */}
        <section
          id="down"
          className="pos-rel section-bg-light-2"
          data-midnight="black"
        >
          {/* pos-rel start */}
          <div className="pos-rel flex-min-height-100vh">
            {/* width-100perc start */}
            <div className="width-100perc padding-bottom-120">
              {/* padding-top-90 start */}
              <div className="padding-top-120 text-color-black">
                <h2 className="headline-l container hidden-box">
                  <span className="anim-slide js-scrollanim">Jobs</span>
                </h2>
                {/* accordion start */}
                <div className="accordion accordion_underline js-accordion margin-top-60">
                  {/* accordion__tab start */}
                  <div className="accordion__tab js-accordion-tab">
                    <div className="accordion__btn js-accordion-btn container js-pointer-large">
                      <h6 className="accordion__btn-title headline-xs margin-top-bottom-30">
                        2D - After Effects Animator
                      </h6>
                    </div>
                    <div className="accordion__content js-accordion-content container">
                      <p className="body-text-l">
                        We are seeking a creative After Effects Animator to join
                        our fast-growing team. The ideal candidate is nimble and
                        able to work in various styles and techniques to bring
                        2D characters to life. This candidate is also able to
                        work well within a team, take instructions and
                        collaborate with others to create high-level animation
                        for our original content and external clients. The
                        ability to also be organized and communicate effectively
                        are a requirement.
                        <br />
                        {/* <br />
                        Hammock sriracha paleo, iPhone swag af flexitarian hella
                        pickled neutra pug. Trust fund chicharrones iPhone
                        stumptown chia. Godard 3 wolf moon tacos try-hard, raw
                        denim locavore messenger bag portland. Blue bottle
                        hashtag schlitz etsy knausgaard, aesthetic tacos freegan
                        lomo deep v fashion axe. */}
                      </p>
                    </div>
                  </div>
                  {/* accordion__tab end */}
                  {/* accordion__tab start */}
                  <div className="accordion__tab js-accordion-tab">
                    <div className="accordion__btn js-accordion-btn container js-pointer-large">
                      <h6 className="accordion__btn-title headline-xs margin-top-bottom-30">
                        2D - ToonBoom Animator
                      </h6>
                    </div>
                    <div className="accordion__content js-accordion-content container">
                      <p className="body-text-l">
                        We are seeking a creative ToonBoom Harmony Animator to
                        join our fast-growing team. The ideal candidate is
                        nimble and able to work in various styles and techniques
                        to bring 2D characters to life. This candidate is also
                        able to work well within a team, take instructions and
                        collaborate with others to create high-level animation
                        for our original content and external clients. The
                        ability to also be organized and communicate effectively
                        are a requirement.
                        <br />
                        {/* <br />
                        Hammock sriracha paleo, iPhone swag af flexitarian hella
                        pickled neutra pug. Trust fund chicharrones iPhone
                        stumptown chia. Godard 3 wolf moon tacos try-hard, raw
                        denim locavore messenger bag portland. Blue bottle
                        hashtag schlitz etsy knausgaard, aesthetic tacos freegan
                        lomo deep v fashion axe. */}
                      </p>
                    </div>
                  </div>
                  {/* accordion__tab end */}
                  {/* accordion__tab start */}
                  <div className="accordion__tab js-accordion-tab">
                    <div className="accordion__btn js-accordion-btn container js-pointer-large">
                      <h6 className="accordion__btn-title headline-xs margin-top-bottom-30">
                        Designer
                      </h6>
                    </div>
                    <div className="accordion__content js-accordion-content container">
                      <p className="body-text-l">
                        The designer will work alongside the team to develop the
                        creative vision and style of projects. The ideal
                        candidate will be comfortable developing the look and
                        feel of projects using various design and illustration
                        styles and must have a good understanding of how their
                        styleframes translate into animation and design
                        projects.
                        <br />
                        {/* <br />
                        Hammock sriracha paleo, iPhone swag af flexitarian hella
                        pickled neutra pug. Trust fund chicharrones iPhone
                        stumptown chia. Godard 3 wolf moon tacos try-hard, raw
                        denim locavore messenger bag portland. Blue bottle
                        hashtag schlitz etsy knausgaard, aesthetic tacos freegan
                        lomo deep v fashion axe. */}
                      </p>
                    </div>
                  </div>

                  <div className="accordion__tab js-accordion-tab">
                    <div className="accordion__btn js-accordion-btn container js-pointer-large">
                      <h6 className="accordion__btn-title headline-xs margin-top-bottom-30">
                        Social Media Manager
                      </h6>
                    </div>
                    <div className="accordion__content js-accordion-content container">
                      <p className="body-text-l">
                        At Zero Gravity, we are all about learning and growing
                        and as part of our mission to grow the next generation
                        of animation and media talent in Africa, we are always
                        on the lookout for talented and driven individuals to
                        join our team as Interns. This position is available for
                        animators, illustrators, designers and social media
                        interns. You’ll get to work alongside some of the most
                        talented thinkers and makers, creating exciting projects
                        for some amazing brands. It's exciting and sometimes
                        fast-paced and no two days are exactly the same but you
                        can rest assured that you'll learn a tonne and have fun
                        with our growing family.
                        <br />
                        {/* <br />
                        Hammock sriracha paleo, iPhone swag af flexitarian hella
                        pickled neutra pug. Trust fund chicharrones iPhone
                        stumptown chia. Godard 3 wolf moon tacos try-hard, raw
                        denim locavore messenger bag portland. Blue bottle
                        hashtag schlitz etsy knausgaard, aesthetic tacos freegan
                        lomo deep v fashion axe. */}
                      </p>
                    </div>
                  </div>

                  {/* <div className="accordion__tab js-accordion-tab">
                    <div className="accordion__btn js-accordion-btn container js-pointer-large">
                      <h6 className="accordion__btn-title headline-xs margin-top-bottom-30">
                        Technical Assistant
                      </h6>
                    </div>
                    <div className="accordion__content js-accordion-content container">
                      <p className="body-text-l">
                        Green juice roof party kombucha mixtape chartreuse. Tofu
                        retro cold-pressed letterpress coloring book gastropub
                        yr asymmetrical cred bicycle rights snackwave XOXO raw
                        denim keytar. Coloring book poutine church-key, selfies
                        asymmetrical pickled banjo green juice PBR&amp;B.
                        Chicharrones you probably haven't heard of them bushwick
                        stumptown yuccie authentic schlitz next level franzen
                        hoodie. Cardigan disrupt fashion axe post-ironic,
                        cold-pressed woke hoodie letterpress YOLO schlitz
                        sartorial etsy prism squid church-key. Pinterest
                        lumbersexual forage chillwave godard pitchfork
                        vexillologist deep v chia truffaut four dollar toast
                        food truck meditation. Hot chicken coloring book cliche
                        YOLO, crucifix kale chips swag hexagon raclette paleo
                        next level austin.
                        <br />
                        <br />
                        Hammock sriracha paleo, iPhone swag af flexitarian hella
                        pickled neutra pug. Trust fund chicharrones iPhone
                        stumptown chia. Godard 3 wolf moon tacos try-hard, raw
                        denim locavore messenger bag portland. Blue bottle
                        hashtag schlitz etsy knausgaard, aesthetic tacos freegan
                        lomo deep v fashion axe.
                      </p>
                    </div>
                  </div> */}
                  {/* accordion__tab end */}
                </div>
                {/* accordion end */}
              </div>
              {/* padding-top-90 end */}
              {/* padding-top-90 start */}

              {/* padding-top-90 end */}
            </div>
            {/* width-100perc end */}
          </div>
          {/* pos-rel end */}
        </section>
        {/* services end */}

        {/* form section start */}
        <section
          className="pos-rel bg-img-cover  "
          // style={{backgroundImage: 'url(assets/images/backgrounds/architecture-buildings-city-fog-373965.jpg)'}}
        >
          {/* bg-overlay */}
          {/* <div className="bg-overlay-black" /> */}
          {/* pos-rel start */}
          <div className="pos-rel flex-min-height-100vh ">
            {/* container start */}
            <div className="container small padding-top-bottom-120 form-box">
              <h4 className="headline-xxxxs text-center">Lets Get In Touch!</h4>
              {/* flex-container start */}
              <form
                action
                method="post"
                name="formobrsv"
                id="send_form"
                className="flex-container padding-top-90"
              >
                {/* column start */}
                <div className="four-columns">
                  <div className="column-r-margin-10">
                    <input
                      type="text"
                      name="first_name"
                      placeholder="Enter your name"
                      id="first_name"
                      required
                      className="form-input js-pointer-small"
                    />
                  </div>
                </div>
                {/* column end */}
                {/* column start */}
                <div className="four-columns">
                  <div className="column-l-r-margin-5">
                    <input
                      type="text"
                      name="last_name"
                      placeholder="Enter your last name"
                      id="last_name"
                      required
                      className="form-input js-pointer-small"
                    />
                  </div>
                </div>
                {/* column end */}
                {/* column start */}
                <div className="four-columns">
                  <div className="column-l-margin-10">
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your email address"
                      id="email"
                      required
                      className="form-input js-pointer-small"
                    />
                  </div>
                </div>
                {/* column end */}
                {/* column start */}
                <div className="twelve-columns">
                  <textarea
                    name="message"
                    placeholder="Enter your message"
                    id="message"
                    required
                    className="form-input js-pointer-small"
                    defaultValue={""}
                  />
                </div>
                {/* column end */}
                {/* column start */}
                <div className="twelve-columns text-center padding-top-90">
                  <button id="send" className="border-btn js-pointer-large">
                    <span className="border-btn__inner">submit</span>
                    <span className="btn-wait">Wait</span>
                    <span className="border-btn__lines-1" />
                    <span className="border-btn__lines-2" />
                  </button>
                </div>
                {/* column end */}
              </form>
              {/* flex-container end */}
            </div>
            {/* container end */}
            {/* alert start */}
            <div className="js-popup-fade" id="m_sent">
              <div className="js-popup">
                <div className="popup-icon">
                  <i className="fas fa-check" />
                </div>
                <p className="popup-alert headline-xs">
                  Thank you!
                  <br />
                  Your submission
                  <br />
                  has been received!
                </p>
                <div className="js-popup-close js-pointer-large">
                  <div className="flip-btn" data-text="Close">
                    Close
                  </div>
                </div>
              </div>
            </div>
            {/* alert end */}
            {/* alert start */}
            <div className="js-popup-fade" id="m_err">
              <div className="js-popup">
                <div className="popup-icon">
                  <i className="fas fa-times" />
                </div>
                <p className="popup-alert headline-xs">Error</p>
                <div className="js-popup-close js-pointer-large">
                  <div className="flip-btn" data-text="Close">
                    Close
                  </div>
                </div>
              </div>
            </div>
            {/* alert end */}
          </div>
          {/* pos-rel end */}
        </section>
      </main>
      <Footer />
    </Fragment>
  );
};

export default Jobs;
