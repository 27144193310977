import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import TheHeader from "../../containers/TheHeader";

const Originals = () => {
  return (
    <Fragment>
      {/* header start */}
      <TheHeader />
      {/* main start */}
      <main className="js-animsition-overlay" data-animsition-overlay="true">
        {/* recent posts start */}
        <section
          className="pos-rel originals section-bg-light-2"
          data-midnight="black"
        >
          {/* pos-rel start */}
          <div className="pos-rel shape  padding-top-bottom-120">
            {/* title start */}
            <h2 className="headline-l text-center js-scrollanim">
              <span className="text-color-black" data-text="Originals">
                Originals
              </span>
            </h2>
            <div className="row d-flex flex-justify-center">
              <div className="col-lg-5 col-md-6">
                <p className="text-center headline-xxs text-dark mt-4 p-3">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Accusantium fugiat tenetur ipsa molestias vero facilis{" "}
                  molestiae suscipit aspernatur neque nostrum.
                </p>
              </div>
            </div>
            {/* title end */}
            {/* recent posts end */}
            <div className="container-fluid  padding-top-bottom-90 hidden-box pos-rel ">
              <div className="row g-2">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="hover-box hidden-box pos-rel ">
                    <Link
                      to="/originals/kweeto"
                      className="d-block js-animsition-link js-pointer-large"
                    >
                      <img
                        className="img-hover-scale"
                        src="avatar/kweeto/1.png"
                        // src="assets/images/posts/tattoo-artist/woman-in-pink-long-sleeve-shirt-wearing-eyeglasses-4125604-2.jpg"
                        alt="Post"
                        height="300px"
                        width="100%"
                        style={{ objectFit: "cover" }}
                      />
                      <h3 className="headline-xxxs pos-abs pos-left-bottom hover-move-right">
                        <span className="hidden-box d-block">
                          <span className="anim-slide">Kweeto</span>
                        </span>
                        <span className="hidden-box d-block">
                          <span className="anim-slide tr-delay-01">
                            The Genius Mosquito
                          </span>
                        </span>
                      </h3>
                    </Link>
                    <ul className="pos-abs pos-left-top list list_row list_margin-30px">
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs hover-text-fill js-pointer-small"
                          data-text="Technique: 2D Animation + Live Action"
                        >
                          Technique: 2D Animation + Live Action
                        </a>
                      </li>
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs hover-text-fill tr-delay-01 js-pointer-small"
                          data-text="Format: 52 x 2"
                        >
                          Format: 52 x 2'
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="hover-box hidden-box pos-rel  ">
                    <Link
                      to="/originals/kore"
                      className="d-block js-animsition-link js-pointer-large"
                    >
                      <img
                        className="img-hover-scale"
                        // src="assets/images/posts/home-studio/man-using-light-control-panel-3355358.jpg"
                        src="avatar/kore/1.png"
                        alt="Post"
                        height="300px"
                        width="100%"
                        style={{ objectFit: "cover" }}
                      />
                      <h3 className="headline-xxxs pos-abs pos-left-bottom hover-move-right">
                        <span className="hidden-box d-block">
                          <span className="anim-slide">Kore</span>
                        </span>
                        <span className="hidden-box d-block">
                          <span className="anim-slide tr-delay-01">
                            The power of Afrobeat
                          </span>
                        </span>
                      </h3>
                    </Link>
                    <ul className="pos-abs pos-left-top list list_row list_margin-30px">
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs hover-text-fill js-pointer-small"
                          data-text="Format: 52 x 5"
                        >
                          Format: 52 x 5
                        </a>
                      </li>
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs hover-text-fill tr-delay-01 js-pointer-small"
                          data-text="Technique: 2D Animation"
                        >
                          Technique: 2D Animation
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="hover-box hidden-box pos-rel ">
                    <Link
                      to="/originals/amina"
                      className="d-block js-animsition-link js-pointer-large"
                    >
                      <img
                        className="img-hover-scale"
                        // src="assets/images/posts/decor-and-furniture/man-seating-on-couch-1726074.jpg"
                        src="avatar/amina/1.png"
                        alt="Post"
                        height="300px"
                        width="100%"
                        style={{ objectFit: "cover" }}
                      />
                      <h3 className="headline-xxxs pos-abs pos-left-bottom hover-move-right">
                        <span className="hidden-box d-block">
                          <span className="anim-slide">Amina's</span>
                        </span>
                        <span className="hidden-box d-block">
                          <span className="anim-slide tr-delay-01">
                            Journey
                          </span>
                        </span>
                      </h3>
                    </Link>
                    <ul className="pos-abs pos-left-top list list_row list_margin-30px">
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs hover-text-fill js-pointer-small"
                          data-text="Format: 90 Mins"
                        >
                          Format: 90 Mins
                        </a>
                      </li>
                      <li className="list__item">
                        <a
                          href="#"
                          className="subhead-xxs hover-text-fill tr-delay-01 js-pointer-small"
                          data-text="Technique: 2D Animation"
                        >
                          Technique: 2D Animation
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default Originals;

// <section
//   className="pos-rel originals section-bg-light-2"
//   data-midnight="black"
// >
//   {/* pos-rel start */}
//   <div className="pos-rel shape  padding-top-bottom-120">
//     {/* title start */}
//     <h2 className="headline-l text-center js-scrollanim">
//       <span
//         className="anim-text-double-fill text-color-black  invert"
//         data-text="Originals"
//       >
//         Originals
//       </span>
//       {/* <span
//         className="anim-text-double-fill invert tr-delay-02"
//         data-text="Posts"
//       >
//         Posts
//       </span> */}
//     </h2>
//     {/* title end */}
//     {/* js-2-view-slider start */}
//     <div className="js-2-view-slider padding-top-bottom-90 hidden-box pos-rel">
//       {/* swiper-wrapper start */}
//       <div className="swiper-wrapper">
//         {/* swiper-slide start */}
//         <article className="swiper-slide">
//           <div className="hover-box hidden-box pos-rel margin-left-right-20">
//             <Link
//               to="/originals/amina"
//               className="d-block js-animsition-link js-pointer-large"
//             >
//               <img
//                 className="img-hover-scale"
//                 // src="assets/images/posts/decor-and-furniture/man-seating-on-couch-1726074.jpg"
//                 src="avatar/amina/1.png"
//                 alt="Post"
//               />
//               <h3 className="headline-xxxs pos-abs pos-left-bottom hover-move-right">
//                 <span className="hidden-box d-block">
//                   <span className="anim-slide">Amina's</span>
//                 </span>
//                 <span className="hidden-box d-block">
//                   <span className="anim-slide tr-delay-01">
//                     Journey
//                   </span>
//                 </span>
//               </h3>
//             </Link>
//             <ul className="pos-abs pos-left-top list list_row list_margin-30px">
//               <li className="list__item">
//                 <a
//                   href="#"
//                   className="subhead-xxs hover-text-fill js-pointer-small"
//                   data-text="Format: 90 Mins"
//                 >
//                   Format: 90 Mins
//                 </a>
//               </li>
//               <li className="list__item">
//                 <a
//                   href="#"
//                   className="subhead-xxs hover-text-fill tr-delay-01 js-pointer-small"
//                   data-text="Technique: 2D Animation"
//                 >
//                   Technique: 2D Animation
//                 </a>
//               </li>

//               {/* <li className="list__item">
//                 <a
//                   href="#"
//                   className="subhead-xxs hover-text-fill tr-delay-02 js-pointer-small"
//                   data-text="2020, March 2"
//                 >
//                   2020, March 2
//                 </a>
//               </li> */}
//             </ul>
//           </div>
//         </article>
//         {/* swiper-slide end */}
//         {/* swiper-slide start */}
//         <article className="swiper-slide">
//           <div className="hover-box hidden-box pos-rel margin-left-right-20">
//             <Link
//               to="/originals/kore"
//               className="d-block js-animsition-link js-pointer-large"
//             >
//               <img
//                 className="img-hover-scale"
//                 // src="assets/images/posts/home-studio/man-using-light-control-panel-3355358.jpg"
//                 src="avatar/kore/1.png"
//                 alt="Post"
//               />
//               <h3 className="headline-xxxs pos-abs pos-left-bottom hover-move-right">
//                 <span className="hidden-box d-block">
//                   <span className="anim-slide">Kore</span>
//                 </span>
//                 <span className="hidden-box d-block">
//                   <span className="anim-slide tr-delay-01">
//                     The power of Afrobeat
//                   </span>
//                 </span>
//               </h3>
//             </Link>
//             <ul className="pos-abs pos-left-top list list_row list_margin-30px">
//               <li className="list__item">
//                 <a
//                   href="#"
//                   className="subhead-xxs hover-text-fill js-pointer-small"
//                   data-text="Format: 52 x 5"
//                 >
//                   Format: 52 x 5
//                 </a>
//               </li>
//               <li className="list__item">
//                 <a
//                   href="#"
//                   className="subhead-xxs hover-text-fill tr-delay-01 js-pointer-small"
//                   data-text="Technique: 2D Animation"
//                 >
//                   Technique: 2D Animation
//                 </a>
//               </li>
//             </ul>
//           </div>
//         </article>
//         {/* swiper-slide end */}
//         {/* swiper-slide start */}
//         <article className="swiper-slide">
//           <div className="hover-box hidden-box pos-rel margin-left-right-20">
//             <Link
//               to="/originals/kweeto"
//               className="d-block js-animsition-link js-pointer-large"
//             >
//               <img
//                 className="img-hover-scale"
//                 src="avatar/kweeto/1.png"
//                 // src="assets/images/posts/tattoo-artist/woman-in-pink-long-sleeve-shirt-wearing-eyeglasses-4125604-2.jpg"
//                 alt="Post"
//               />
//               <h3 className="headline-xxxs pos-abs pos-left-bottom hover-move-right">
//                 <span className="hidden-box d-block">
//                   <span className="anim-slide">Kweeto</span>
//                 </span>
//                 <span className="hidden-box d-block">
//                   <span className="anim-slide tr-delay-01">
//                     The Genius Mosquito
//                   </span>
//                 </span>
//               </h3>
//             </Link>
//             <ul className="pos-abs pos-left-top list list_row list_margin-30px">
//               <li className="list__item">
//                 <a
//                   href="#"
//                   className="subhead-xxs hover-text-fill js-pointer-small"
//                   data-text="Technique: 2D Animation + Live Action"
//                 >
//                   Technique: 2D Animation + Live Action
//                 </a>
//               </li>
//               <li className="list__item">
//                 <a
//                   href="#"
//                   className="subhead-xxs hover-text-fill tr-delay-01 js-pointer-small"
//                   data-text="Format: 52 x 2"
//                 >
//                   Format: 52 x 2'
//                 </a>
//               </li>
//             </ul>
//           </div>
//         </article>
//       </div>

//       {/* swiper-button-prev start */}
//       <div className="swiper-button-prev-box">
//         <div className="swiper-button-prev" />
//       </div>
//       {/* swiper-button-prev end */}
//       {/* swiper-button-next start */}
//       <div className="swiper-button-next-box">
//         <div className="swiper-button-next" />
//       </div>
//       {/* swiper-button-next end */}
//       {/* swiper-pagination start */}
//       <div className="pagination-box">
//         <div className="swiper-pagination counter-pagination" />
//       </div>
//       {/* swiper-pagination end */}
//     </div>
//     {/* js-2-view-slider end */}
//   </div>
//   {/* pos-rel end */}
// </section>;
